<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-darkBlue">
            <a class="navbar-brand" href="#"><img src="@/assets/img/logo.png" /></a>
            <button class="navbar-toggler bg-light" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon" style="color:#FFF"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                     <li class="nav-item text-center">
                         <router-link class="nav-link" to="/">Inicio </router-link>
                    </li>
                    <li class="nav-item text-center">
                        <router-link class="nav-link" to="/cursos">Cursos </router-link>
                    </li>
                     <li class="nav-item text-center">
                        <router-link class="nav-link" to="/faqs">Faqs</router-link>
                    </li>
                    <li class="nav-item text-center">
                        <router-link class="nav-link" to="/acerca-de">Acerca de </router-link>
                    </li>
                    <li class="nav-item text-center">
                        <router-link class="nav-link" to="/blog">Blog</router-link>
                    </li>
                </ul>
                <form class="form-inline my-2 my-lg-0">
                    <template v-if="profile == null">
                        <router-link class="btn btn-outline-white my-2 my-sm-0 mr-3" to="/register">Regístrate</router-link>
                        <router-link class="btn btn-golden my-2 my-sm-0" to="/login">Iniciar sesión</router-link>
                    </template>
                    <template v-else>
                        <template v-if="profile.isLogged">
                             <div v-if="showLogout">
                                <span class="text-light">{{profile.email}} </span>
                                <router-link  class="btn btn-outline-white my-2 my-sm-0" to="/logout">Cerrar sesión</router-link>
                            </div>
                            <div v-else>
                                <router-link v-if="profile.current_role==1" class="btn btn-outline-white my-2 my-sm-0" to="/admin">Ir a mi perfil</router-link>
                                <router-link v-if="profile.current_role==2" class="btn btn-outline-white my-2 my-sm-0" to="/profile">Ir a mi perfil</router-link>
                            </div>
                        </template>
                        <template v-else>
                            <router-link class="btn btn-outline-white my-2 my-sm-0 mr-3" to="/register">Regístrate</router-link>
                            <router-link class="btn btn-golden my-2 my-sm-0" to="/login">Iniciar sesión</router-link>
                        </template>

                    </template>
                </form>
            </div>
        </nav>
</template>

<script>
export default {
  name: 'Header',
  props:{
      showLogout: {
        default: false
       },
      profile: {
        default: null
       },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

</style>
