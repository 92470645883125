<template>
  <footer class="mt-auto">
        <nav class="navbar navbar-expand-sm navbar-light bg-darkBlue">
            <div class="" id="">
                <ul class="navbar-nav">
                    <li class="nav-item" style="font-size:11px">
                        <a class="nav-link" href="#">2021 Top Managment Training |</a>
                    </li>
                     <li class="nav-item" style="font-size:11px">
                        <a class="nav-link" href="/terminos-y-condiciones">Términos y condiciones |</a>
                    </li>
                     <li class="nav-item" style="font-size:11px">
                        <a class="nav-link" href="/politica-privacidad">Política de privacidad </a>
                    </li>

                </ul>
                <div class="social-part">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                </div>
            </div>
        </nav>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.bg-darkBlue{
    background: #152F44
}
</style>